<template>
  <div class="cla-left">
      <div class="cal-head ss" >
         <h5><img src="../assets/images/cal-img.png">Availabilty</h5>
         <a href="/edit_availabilty">Edit Availabilty</a>
      </div>
      <div class="check-va">
         <ul class="nav nav-green" v-if="current_calender_slots.length">
            <span v-for="availabilty in current_calender_slots" :key="availabilty.id">
                <li class="green-fa">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>{{formatDateTime(availabilty.start_time)}}-{{formatDateTime(availabilty.end_time)}}
                </li>
              <!--   <li class="red-fa" v-if ="!availabilty.status && availabilty.end_time"><i class="fa fa-times-circle" aria-hidden="true"></i>{{availabilty.start_time}} - {{availabilty.end_time}}</li>
                <li class="red-fa" v-if ="!availabilty.status && !availabilty.end_time"><i class="fa fa-times-circle" aria-hidden="true"></i>Full Day Not Available</li> -->
            </span>
         </ul>
      </div>
      <div class="cal-inner" style="margin-top: 10px !important;">
         <!-- <img src="../assets/images/dumy-cal2.png"> -->

         <FullCalendar :options="calendarOptions" class ="demo-app-calendar"/>
         <p class="date-dec">{{formatDate(todays_date)}}</p>
        
      </div>
   </div>
   
</template>
<script>

import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Auth from '../resources/Auth.js';
import Constant from '../resources/Constant.js';
import moment from "moment-timezone";
// must manually include stylesheets for each plugin
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

export default {
  props:['availability_based_on_date'],
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data: function() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin,timeGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        height:"300px",
        dateClick: this.handleDateClick,
        headerToolbar: {
          left: 'title',
          right: 'prev,next'
        },
        showNonCurrentDates:false,
      },
      user : window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null, 
      current_calender_slots :[],
      todays_date : new Date().toJSON().slice(0,10).replace(/-/g,'-'),
    };
  },
  mounted(){
    //document.getElementsByClassName('fc-daygrid-day');
    //const tiles = document.querySelectorAll(".fc-daygrid-day");
    //tiles.forEach(tile => tile.addEventListener('click', this.handleTileClick))
    //  console.log("user",this.user);
   //   console.log("availability_based_on_date availability_based_on_date",this.availability_based_on_date);
      this.getAvailableDates(this.todays_date);
  },
  methods: {
   /* handleTileClick() {
   //   console.log("logger",this);
    },*/ 
    handleDateClick(arg) {
    //  console.log('arg',arg);
      this.$emit('unavailabiltyCalender',arg);
      this.todays_date = arg.dateStr;
      this.getAvailableDates(this.todays_date);
    },
    formatDate(dateString) {
      return moment(dateString,"Y-M-D HH:mm:ss").format('MMM Do, YYYY');
    },
    formatDateTime(dateString) {
      return moment(dateString,"HH:mm").format('LT');
    },
    getAvailableDates(date){
    //  console.log("logger",date);

    // if(this.availability_based_on_date == undefined){
        this.todays_date = date;
           let month = moment(date,"Y-M-D HH:mm:ss").format('M');
           let day   = moment(date,"Y-M-D HH:mm:ss").format('D');
           let year  = moment(date,"Y-M-D HH:mm:ss").format('Y');
           let get_availabilty = Constant.getbaseurl()+'/therapist/get-therapist-data';
           this.axios.post(get_availabilty,{"therapist_id":this.user.id,"month":month,"date":day,"year":year})
           .then(({data}) => {
                let response_data = data.data;
                if(response_data.available_slots !== undefined){
                   this.current_calender_slots = response_data.available_slots;
                }else{
                   this.current_calender_slots = response_data;
                }
               // console.log("current_calender_slots",this.current_calender_slots);
           })
           .catch((error) => {
               if(error.response.status == 401){
                   this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
                   Auth.logout();
                   this.$router.push('login');
               }else{
                   this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
           });
     //}
    },
    getCalenderEvent(){
        let get_availabilty = Constant.getbaseurl()+'/web/monthly/availability';
        this.axios.post(get_availabilty,{"start_time":this.calender_start_date,"end_time":this.calender_end_date,'current_date':this.todays_date})
       .then(({data}) => {
            let response_data = data.data;
            this.calendarOptions.events = response_data.unavailable_dates;
             
       })
       .catch((error) => {
           if(error.response.status == 401){
               this.$swal({icon: 'error', title: "You are not authorized user",background:'#cb1111',color:'#fff'});
               Auth.logout();
               this.$router.push('login');
           }else{
               this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
           }
       });
    },

  }
};
</script>
<style>

.fc .fc-toolbar-title {
    font-size: 1.25em !important;
    margin: 0;
}
button.fc-prev-button.fc-button.fc-button-primary {
    border: none !important;
    background: none !important;
    color: #2bbf76 !important;
}
button.fc-next-button.fc-button.fc-button-primary {
    border: none !important;
    background: none !important;
    color: #2bbf76 !important;
}
table,.fc-scrollgrid {
    border: none !important;
}
td.fc-daygrid-day {
    border: none;
    cursor: pointer;
}
.fc.fc-daygrid-day-frame {
    cursor: pointer;
}
a.fc-daygrid-day-number {
    text-decoration: none;
    color: black;
}
a.fc-col-header-cell-cushion {
    color: #aeafb3;
    text-decoration: none;
}
.fc-daygrid-day-top {
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
}
td.fc-day-other {
    visibility: hidden;
}
td.fc-day-future.fc-day-other {
    visibility: hidden;
}
th.fc-day {
    border: none;
}
.fc .fc-scrollgrid table {
   border: none !important;
}
.fc-day-disabled {
    background: #00000000 !important;
}
.cal-inner .fc-daygrid-day-events {
display: none;
}
.cal-inner td.fc-daygrid-day {
vertical-align: middle;
}
.cal-inner td.fc-daygrid-day div {
min-height: auto;
}
.cal-inner td, .cal-inner th {
border: 0 !important;
}
td.fc-daygrid-day.fc-day.fc-day-today {
    background: #2bbf76 !important;
}
td.fc-daygrid-day.active_date{
    background: #b5d9c7 !important;
}
.date-dec {
    font-size: 18px;
    color: #0d5c35;
    opacity: 0.65;
    margin: 10px 0px 3px 0px;
    font-family: sans-serif;
    font-weight: bold;
}
.green-fa {
    height: 25px;
    display: flex;
    background: rgba(43, 191, 118, .25);
    border-radius: 4px;
    align-items: center;
    padding: 5px 8px;
    font-size: 12px;
    letter-spacing: 0px;
    color: #222635;
}
</style>

