import axios from 'axios';
import moment from "moment-timezone";
import Constant from '../resources/Constant.js';

class Auth {
    constructor () {
        this.token = window.localStorage.getItem('token');
        let userData =  window.localStorage.getItem('user');
        this.user = userData ? JSON.parse(userData) : null;
        if(moment.tz.guess()){
            window.localStorage.setItem('timezone',moment.tz.guess());
            axios.defaults.headers.common['timezone'] = moment.tz.guess();
        }
        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }
    login (token, user) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
        if(moment.tz.guess()){
            window.localStorage.setItem('timezone',moment.tz.guess());
            axios.defaults.headers.common['timezone'] = moment.tz.guess();
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.token = token;
        this.user = user;
    }
    check () {
        return !! this.token;
    }
    getUser(){
             return this.user;
    }
    logout () {
        let logout_url = Constant.getbaseurl()+'/app_logout';
        axios.post(logout_url,{})
          .then(() => {
              //this.$swal({icon: 'success', title: "Logout Successfully"});
             // this.$router.go('/');
          })
          .catch((error) => {
               console.log(error);
               if(error.response != undefined && error.response.status == 401){
                //   this.$swal({icon: 'error', title: "Logout Successfully",background:'#cb1111',color:'#fff'});
               }else{
                  // this.$swal({icon: 'error', title: error.response.data.message,background:'#cb1111',color:'#fff'});
               }
          });
        // window.localStorage.clear();
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('route');
        window.localStorage.removeItem('socket');
        window.localStorage.removeItem('socket_id');
        axios.defaults.headers.common['fcm_id'] = null;
        axios.defaults.headers.common['Authorization'] = null;
        this.user = null;
        this.token = null;
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (const registration of registrations) {
               //   console.log('serviceWorker unregistered');
                  registration.unregister();
                }
            });
        }
    }
}
export default new Auth();