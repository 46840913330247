<template>
  <div class="home">
    <HeaderPage :current_loaded_panel="current_loaded_panel"/>
    <div class="dashboad-page">
       <div class="custom-container">
          <div class="dashboad-page-wrapper">
             <SidebarPage :current_loaded_panel="current_loaded_panel"/>
             <div class="right-bar">
                <div class="row">
                   <div class="col-md-12">
                      <div class="therapist-found-left session-therapist">
                         <h3>Profile Information</h3>
                         <div class="row">
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">Display Name</label>
                                  <input type="text" class="form-control" id="exampleInputPassword1" placeholder="">
                               </div>
                            </div>
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">D.O.B</label>
                                  <input type="text" class="form-control" id="exampleInputPassword1" placeholder="">
                               </div>
                            </div>
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">Phone Number</label>
                                  <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Enter Name">
                               </div>
                            </div>
                         </div>
                         <div class="row mt-4">
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">Email Address</label>
                                  <input type="email" class="form-control" id="exampleInputPassword1" placeholder="">
                               </div>
                            </div>
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">App Language</label>
                                  <select id="inputState" class="form-control">
                                     <option selected>Choose...</option>
                                     <option>English</option>
                                  </select>
                               </div>
                            </div>
                            <div class="col-md-4">
                               <div class="form-group">
                                  <label for="exampleInputPassword1" class="pb-2">App Currency</label>
                                  <select id="inputState" class="form-control">
                                     <option selected>Choose...</option>
                                     <option>Dollar</option>
                                  </select>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-12 ss">
                            <div class="d-flex align-items-center justify-content-end" style="margin-top: 20px;">
                               <a href="#" class="cancel-btn btn-c">Cancel</a>
                               <div class="submit-reson-btn py-1 ms-3"><button>Sign Up</button></div>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-12 mt-4">
                      <div class="therapist-found-left session-therapist">
                         <div class="col-md-12 text-center">
                            <img src="https://cdn-icons-png.flaticon.com/512/6615/6615039.png" style="width: 200px;">
                            <h3>Location Access</h3>
                            <p>Please allow the access of location to provide the services.</p>
                            <div class="submit-reson-btn py-1 ms-3 text-center"><button style="padding: 8px 60px;">Allow Access</button></div>
                         </div>
                      </div>
                   </div>
                   <div class="col-md-12 mt-4">
                      <div class="therapist-found-left session-therapist">
                         <div class="col-md-12 text-center">
                            <img src="https://www.iconpacks.net/icons/2/free-handshake-icon-3312-thumb.png" style="width: 200px;">
                            <h3>Welcome Jessica,</h3>
                            <p>You are now ready to be connected with our specialised network of professionals.</p>
                            <div class="submit-reson-btn py-1 ms-3 text-center"><button style="padding: 8px 60px;">Get Started</button></div>
                         </div>
                      </div>
                   </div>
                   <div class="row">
                     <div class="col-md-6 mt-4">
                        <div class="therapist-found-left session-therapist">
                           <div class="col-md-12 text-center">
                              <div class="accordion" id="accordionExample">
                                 <div class="accordion-item">
                                   <h2 class="accordion-header" id="headingOne">
                                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Life Coach
                                     </button>
                                   </h2>
                                   <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div class="accordion-body">
                                       <ul class="rdio-list">
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                       </ul>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                           </div>
                           <div class="col-md-12 text-center">
                              <div class="accordion" id="accordionExample">
                                 <div class="accordion-item">
                                   <h2 class="accordion-header" id="headingOne">
                                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Business Coach
                                     </button>
                                   </h2>
                                   <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div class="accordion-body">
                                       <ul class="rdio-list">
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                       </ul>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 mt-4">
                        <div class="therapist-found-left session-therapist">
                           <div class="col-md-12 text-center">
                              <div class="accordion" id="accordionExample">
                                 <div class="accordion-item">
                                   <h2 class="accordion-header" id="headingOne">
                                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Individual Therapy
                                     </button>
                                   </h2>
                                   <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div class="accordion-body">
                                       <ul class="rdio-list">
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                       </ul>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                           </div>
                           <div class="col-md-12 text-center">
                              <div class="accordion" id="accordionExample">
                                 <div class="accordion-item">
                                   <h2 class="accordion-header" id="headingOne">
                                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Couple Therapy
                                     </button>
                                   </h2>
                                   <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div class="accordion-body">
                                       <ul class="rdio-list">
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                       </ul>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                           </div>
                           <div class="col-md-12 text-center">
                              <div class="accordion" id="accordionExample">
                                 <div class="accordion-item">
                                   <h2 class="accordion-header" id="headingOne">
                                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       Children Therapy
                                     </button>
                                   </h2>
                                   <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                     <div class="accordion-body">
                                       <ul class="rdio-list">
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                          <li class=""><label class="rdo">Online platforms
                                             <input type="radio" class="step-two" value="online_platforms" name="practice">
                                             <span class="checkmark"></span>
                                             </label>
                                          </li>
                                       </ul>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                           </div>
                           
                        </div>
                     </div>
                     <div class="col-md-8 mt-4">
                        <div class="therapist-found-left session-therapist">
                           <div class="col-md-12">
                              <button class="submit-reson-btn py-1 ms-3 float-right avl">Same For Allow</button>
                              <h2>Add Availablity</h2>
                              <h3>Set Your Working Hours</h3>
                              <p>Only Selected Days Will be marked as available</p>
                              <table class="table table-reponsive table-bordered">
                                 <tbody>
                                    <tr>
                                      <td><input type="checkbox" id="availablity" name="monday" value="monday"> &nbsp;

                                      <label for="monday">Monday</label></td>
                                      <td>
                                      <select class="form-control">
                                          <option>Select Time</option>
                                          <option>01:00</option>
                                          <option>09:00</option>
                                      </select>
                                      </td>
                                      <td>
                                      <select class="form-control">
                                          <option>Select Time</option>
                                          <option>01:00</option>
                                          <option>09:00</option>
                                      </select>
                                      </td>
                                      <td><button class="btn btn-primary"><b>+</b></button></td>
                                      <td><button class="btn btn-danger"><b>-</b></button></td>
                                    </tr>
                                    <tr>
                                       <td><input type="checkbox" id="availablity" name="monday" value="monday"> &nbsp;
                                       <label for="monday">Tuesday</label></td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td><button class="btn btn-primary"><b>+</b></button></td>
                                       <td><button class="btn btn-danger"><b>-</b></button></td>
                                     </tr>
                                     <tr>
                                       <td><input type="checkbox" id="availablity" name="monday" value="monday"> &nbsp;
                                       <label for="monday">Webnesday</label></td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td><button class="btn btn-primary"><b>+</b></button></td>
                                       <td><button class="btn btn-danger"><b>-</b></button></td>
                                     </tr>
                                     <tr>
                                       <td><input type="checkbox" id="availablity" name="monday" value="monday"> &nbsp;
                                       <label for="monday">Thursday</label></td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td><button class="btn btn-primary"><b>+</b></button></td>
                                       <td><button class="btn btn-danger"><b>-</b></button></td>
                                     </tr>
                                     <tr>
                                       <td><input type="checkbox" id="availablity" name="monday" value="monday"> &nbsp;
                                       <label for="monday">Friday</label></td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td>
                                       <select class="form-control">
                                           <option>Select Time</option>
                                           <option>01:00</option>
                                           <option>09:00</option>
                                       </select>
                                       </td>
                                       <td><button class="btn btn-primary"><b>+</b></button></td>
                                       <td><button class="btn btn-danger"><b>-</b></button></td>
                                     </tr>
                                 </tbody>
                               </table>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-4 mt-4">
                        <div class="therapist-found-left session-therapist">
                           <div class="mb-3">
                              <label for="exampleFormControlTextarea1" class="form-label">Your Biography</label>
                              <textarea class="form-control" id="exampleFormControlTextarea1" rows="15"></textarea>
                            </div>
                        </div>
                     </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <FooterPage/>
 </div>
  
</template>
<script>
// @ is an alias to /src
import HeaderPage from '@/components/HeaderPage.vue'
import SidebarPage from '@/components/SidebarPage.vue'
import FooterPage from '@/components/FooterPage.vue'

export default {
 props:['available_status','client_wait','intial_loaded','call_initiated_details','current_loaded_panel'],
 emits:['moveToCallDetails'],
  name: 'TipsView',
  components: {
    HeaderPage,SidebarPage,FooterPage
  },
  methods:{
    moveToCallDetails(details){
     this.$emit('moveToCallDetails',details);
    }
  }
}
</script>
