<template>
<div class="modal modal-width" id ="search_request_modal" :style="{'background-color':background_color,'display':display_call_initate_color}">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <div class="session-timer-inner">
                    <img src="../assets/images/book-one.svg" alt="" class="mx-auto d-block mb-3">
                    <h5 style="text-align:center !important;">You have been accepted by <span style="text-transform:capitalize;">{{ search_modal_details ? search_modal_details.user_name : 'Client' }}</span> for 60 minutes session</h5>
                    <p style="font-size:16px;color: #22263573;margin-left: 12px;margin-top: 16px;margin-bottom: -14px;">Please make sure</p>
                    <ul class="reason-list" style="list-style-type: circle !important;">
                        <li>
                            <label class="rad-ct">Microphone On
                            </label>
                        </li>
                        <li>
                            <label class="rad-ct">Camera On
                            </label>
                        </li>
                        <li>
                            <label class="rad-ct">Good Lighting
                            </label>
                        </li>
                        
                        <li>
                            <label class="rad-ct">No Background Interference
                            </label>
                        </li>
                    </ul>
                    <div class="session-btn mt-5">
                        <a href="#" class="cm-btn" @click.prevent="hideOfflineModal()"><span>Noted - Start Session</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
props:['show_call_modal','display_call_initate_color','search_modal_details'],
data() {
return {
background_color:"rgb(0 0 0 / 44%)",
//  display_color:"none",
};
},
mounted(){
//console.log("Hello",this.display_call_initate_color);
},
methods:{
hideOfflineModal(){
this.$emit('startCallModal',{'closer':1,'load':0});
}
}
}
</script>
<style scoped>
.reason-list li {
list-style: outside;
margin-left: 30px;
}
.reason-list li label {
padding-left: 0;
}
</style>